import { useContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { CustomModal } from '@mth/components/CustomModal/CustomModals'
import { fontFamilyEnum, HubspotITRAnswers } from '@mth/enums'
import { PUBLIC_OPEN_ED_API } from '@mth/envs'
import { getOpenEdToken } from '@mth/graphql/queries/intent-to-reenroll'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { ITRModalType } from './itrModal.type'
import { MthLoadingWrapper } from '../shared/MthLoadingWrapper/MthLoadingWrapper'

export const ITRModal: React.FC<ITRModalType> = ({ onCancel, selectedItrOption, onSubmit }) => {
  const { me } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  const fetchOpenEdToken = async () => {
    setLoading(true)
    try {
      const { data } = await client.query({
        query: getOpenEdToken,
        variables: {
          userId: Number(me?.user_id),
        },
        fetchPolicy: 'network-only',
      })
      const redirect = encodeURIComponent('/parents/migrate')
      let url = `${PUBLIC_OPEN_ED_API}auth/login-token?`

      url += `token=${encodeURIComponent(data.getOpenEdToken)}&redirect=${redirect}`
      window.open(url, '_blank')
      if (onSubmit) await onSubmit(true)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }
  const displaySwitch = () => {
    if (
      [HubspotITRAnswers.YES, HubspotITRAnswers.YES_NEW, HubspotITRAnswers.YES_SOME].includes(
        selectedItrOption as HubspotITRAnswers,
      )
    ) {
      return (
        <MthLoadingWrapper loading={loading} minHeight='10px'>
          <Box>
            <Typography
              sx={{ marginBottom: '15px', marginTop: '10px', fontSize: 14, fontFamily: fontFamilyEnum.HeavyFont }}
            >
              Thank you for filling out your intent to re-enroll for the 25-26 school year! We’re excited to invite you
              into OpenEd’s new platform, which will replace InfoCenter starting next year.{' '}
              <button
                onClick={fetchOpenEdToken}
                style={{
                  all: 'unset', // resets most native button styles
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  display: 'inline',
                }}
              >
                {' '}
                click here
              </button>{' '}
              to check it out!
            </Typography>
          </Box>
        </MthLoadingWrapper>
      )
    }
    return (
      <MthLoadingWrapper loading={loading} minHeight='10px'>
        <Box>
          <Typography
            sx={{ marginBottom: '15px', marginTop: '10px', fontSize: 14, fontFamily: fontFamilyEnum.HeavyFont }}
          >
            Please{' '}
            <button
              onClick={fetchOpenEdToken}
              style={{
                all: 'unset', // resets most native button styles
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
                display: 'inline',
              }}
            >
              {' '}
              click here
            </button>{' '}
            to submit your Intent to Re-enroll for the 2025-26 school year. You’ll be taken to OpenEd’s new platform,
            which will replace InfoCenter starting next year!
          </Typography>
        </Box>
      </MthLoadingWrapper>
    )
  }

  return (
    <CustomModal
      title='Intent to Re-Enroll'
      description={
        !selectedItrOption
          ? `It's time to secure your spot for next school year!`
          : 'You’re invited to OpenEd’s new platform!'
      }
      descriptionSx={{ fontSize: 17, fontFamily: fontFamilyEnum.HeavyFont, fontWeight: 'bold' }}
      subDescription={displaySwitch()}
      confirmStr={'Close'}
      cancelStr='Cancel'
      onClose={onCancel}
      showCancel={false}
      onConfirm={onCancel}
    />
  )
}
